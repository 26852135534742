/*
 * @Descripttion:
 * @Author: yuyang
 * @Date: 2022-01-21 16:16:25
 * @LastEditors: yuyang
 * @LastEditTime: 2022-05-16 14:13:30
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

export function getIntendedCustomerList(
  realName: string,
  mobile: string,
  startRecDate: number,
  endRecDate: number,
  oauthClientId: string,
  status: string,
  page: number,
  limit: number
): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesIntentionClient`,
    method: 'GET',
    params: {
      realName,
      mobile,
      startRecDate,
      endRecDate,
      oauthClientId,
      status,
      page,
      limit,
    },
  });
}

export function addVisitRecords(data): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesIntentionClientReviewRecord`,
    method: 'POST',
    data,
  });
}

export function addRecordsAndOpen(data): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesIntentionClient/turnV2`,
    method: 'POST',
    data,
  });
}

export function getVisitRecordsList(
  intentionCode: string
): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesIntentionClientReviewRecord`,
    method: 'GET',
    params: { intentionCode },
  });
}

export function getCurrentCodeInfo(code: string): Promise<BaseResponse> {
  return request({
    url: `/agency-resource/1/agenciesIntentionClient/intentionCode/${code}`,
    method: 'GET',
  });
}
