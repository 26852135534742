











































































































































































































































































import Vue from 'vue';
import moment from 'moment';
import * as api from '@/api/intendedCustomer';
export default Vue.extend({
  name: 'intendedCustomerList',
  data() {
    return {
      tableLoading: false,
      searchForm: {
        realName: '',
        mobile: '',
        creationTime: [],
        oauthClientId: 'SUB_YUNSUANFANG',
        status: '0,1',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      intendedCustomerColumns: [
        {
          title: '线索单号',
          dataIndex: 'intentionCode',
          width: '160px',
          fixed: 'left',
          scopedSlots: { customRender: 'intentionCode' },
        },
        {
          title: '联系人',
          dataIndex: 'realName',
          ellipsis: true,
        },
        {
          title: '联系电话',
          dataIndex: 'mobile',
        },
        {
          title: '申请说明',
          dataIndex: 'applyExplain',
          width: '140px',
          ellipsis: true,
        },
        {
          title: '申请时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
        },
        {
          title: '申请产品',
          dataIndex: 'oauthClientApplicationName',
          width: '120px',
          ellipsis: true,
        },
        {
          title: '渠道来源',
          dataIndex: 'intentionSourceName',
        },
        {
          title: '分销渠道',
          dataIndex: 'distribution',
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '最近回访',
          dataIndex: 'lastReviewTime',
          scopedSlots: { customRender: 'lastReviewTime' },
        },
        {
          title: '最近回访人',
          dataIndex: 'lastReviewUserRealName',
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '160px',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      intendedCustomerData: [] as any,
      applicationList: [], //申请产品，下拉菜单

      visitRecordsBasicData: {} as any, // 临时存储行数据

      /* openTrialModal-开通试用弹框 */
      openTrialModalVisible: false,
      openTrialModalConfirmLoading: false,
      /* openTrialModal-开通试用弹框 */

      /* visitRecordsModal-回访记录弹框 */
      submitLoading: false,
      timelineLoading: false,
      basicInfoLoading: false,
      submitAndOpenLoading: false,
      visitRecordsModalVisible: false,
      allTimeLineData: [] as any,
      currentTimeLineData: [] as any,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      visitRecordsForm: {
        visitRecords: '', //回访记录
      },
      visitRecordsFormRules: {
        visitRecords: [
          { required: true, message: '请输入回访记录', trigger: 'blur' },
        ],
      },
      /* visitRecordsModal-回访记录弹框 */
    };
  },
  mounted() {
    this.getIntendedCustomerList();
  },
  methods: {
    moment,
    // 获取主列表数据
    getIntendedCustomerList() {
      this.tableLoading = true;
      api
        .getIntendedCustomerList(
          this.searchForm.realName,
          this.searchForm.mobile,
          this.searchForm.creationTime[0],
          this.searchForm.creationTime[1],
          this.searchForm.oauthClientId,
          this.searchForm.status,
          this.pagination.current,
          this.pagination.pageSize
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.intendedCustomerData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getIntendedCustomerList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.getIntendedCustomerList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getIntendedCustomerList();
    },
    // 初始化回访记录时间轴数据
    initTimeLineData() {
      const allArr = this.allTimeLineData;
      const length = this.allTimeLineData.length;
      if (length >= 5) {
        this.currentTimeLineData = allArr.filter(
          (item, index) => index < 2 || index > length - 3
        );
      } else {
        this.currentTimeLineData = [...allArr];
      }
    },
    // 切换回访记录时间轴数据呈现状态
    changeTimeLineData(status) {
      const allArr = this.allTimeLineData;
      const length = this.allTimeLineData.length;
      if (status === 'open') {
        this.currentTimeLineData = [...allArr];
      } else {
        this.currentTimeLineData = allArr.filter(
          (item, index) => index < 2 || index > length - 3
        );
      }
    },
    // 打开回访记录弹框
    showVisitRecordsModal(record) {
      this.visitRecordsBasicData = record;
      this.visitRecordsModalVisible = true;
      this.getVisitRecordsList(record.intentionCode);
    },
    // 获取回访记录时间轴数据
    getVisitRecordsList(id: string) {
      this.timelineLoading = true;
      api
        .getVisitRecordsList(id)
        .then((res) => {
          if (res.status === 200) {
            this.allTimeLineData = res.result.list;
            this.initTimeLineData();
          }
        })
        .finally(() => {
          this.timelineLoading = false;
        });
    },
    // 获取关联单号相关信息
    getCurrentCodeInfo(code: string) {
      this.timelineLoading = true;
      this.basicInfoLoading = true;
      api
        .getCurrentCodeInfo(code)
        .then((res) => {
          if (res.status === 200) {
            this.visitRecordsBasicData = res.result;
            this.allTimeLineData = res.result.reviewRecordList;
            this.initTimeLineData();
          }
        })
        .finally(() => {
          this.timelineLoading = false;
          this.basicInfoLoading = false;
        });
    },
    // 展示开通试用确认弹框
    showOpenTrialModal(record) {
      this.visitRecordsBasicData = record;
      this.openTrialModalVisible = true;
    },
    // 开通试用确认提交
    openTrialModalHandleOk() {
      const params = {
        intentionCode: this.visitRecordsBasicData.intentionCode,
      };
      this.openTrialModalConfirmLoading = true;
      api
        .addRecordsAndOpen(params)
        .then((res) => {
          if (res.status === 200) {
            if (res.result.isMessage) {
              this.$message.error(res.result.message);
            } else {
              this.$message.success('开通试用账号成功！');
            }
            this.openTrialModalVisible = false;
            this.handleSearchSubmit();
          }
        })
        .finally(() => {
          this.openTrialModalConfirmLoading = false;
        });
    },
    // 回访记录弹框取消
    handleFormCancel() {
      this.visitRecordsModalVisible = false;
      (this.$refs.visitRecordsForm as HTMLFormElement).resetFields();
    },
    // 回访记录弹框提交
    handleFormSubmit() {
      (this.$refs.visitRecordsForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          const params = {
            intentionCode: this.visitRecordsBasicData.intentionCode,
            description: this.visitRecordsForm.visitRecords, //回访记录
          };
          this.submitLoading = true;
          api
            .addVisitRecords(params)
            .then((res) => {
              if (res.status === 200) {
                this.$message.success('提交回访记录成功！');
                this.handleFormCancel();
                this.handleSearchSubmit();
              }
            })
            .finally(() => {
              this.submitLoading = false;
            });
        }
      });
    },
    //提交回访记录并开通试用
    handleFormSubmitAndOpen() {
      (this.$refs.visitRecordsForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          const params = {
            intentionCode: this.visitRecordsBasicData.intentionCode,
            description: this.visitRecordsForm.visitRecords, //回访记录
          };
          this.submitAndOpenLoading = true;
          api
            .addRecordsAndOpen(params)
            .then((res) => {
              if (res.status === 200) {
                if (res.result.isMessage) {
                  this.$message.warning(res.result.message);
                } else {
                  this.$message.success('提交回访记录并开通试用账号成功！');
                }
                this.handleFormCancel();
                this.handleSearchSubmit();
              }
            })
            .finally(() => {
              this.submitAndOpenLoading = false;
            });
        }
      });
    },
    //回访记录弹框关闭事件处理
    handleVisitRecordsModalCancel() {
      (this.$refs.visitRecordsForm as HTMLFormElement).resetFields();
    },
  },
});
